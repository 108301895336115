import React from 'react'
// import Image from './image'
import imgJamel from '../images/jamel.png'

const Hero = () => (
  <div className="profile-page">
    <div className="wrapper">
      <div className="page-header page-header-small" filter-color="green">
        <div
          className="page-header-image"
          data-parallax="true"
          style={{
            backgroundImage: `url('../images/header.jpg')`,
            transform: `translate3d(0px, 0px, 0px)`,
          }}
        />
        <div className="container">
          <div className="content-center">
            <div className="cc-profile-image">
              <a href="/#">
                <img src={imgJamel} alt="" />
              </a>
            </div>
            <div className="h2 title">Jamel AÏT OUARRAOU</div>
            <p className="category text-white">
              Freelance Developer - Microsoft .NET &amp; JavaScript - Front &amp; Back End
            </p>
            {/* <a
              className="btn btn-primary smooth-scroll mr-2 aos-init aos-animate"
              href="./CV Jamel AIT OUARRAOU - Février 2019.pdf"
              data-aos="zoom-in"
              data-aos-anchor="data-aos-anchor"
            >
              Download CV (fr)
            </a> */}
          </div>
        </div>
        <div className="section">
          <div className="container">
            <div className="button-container">
              <a
                className="btn btn-default btn-round btn-lg btn-icon"
                href="https://www.linkedin.com/in/jait"
                rel="tooltip"
                title=""
                data-original-title="LinkedIn"
              >
                <i className="fa fa-linkedin" />
              </a>
              <a
                className="btn btn-default btn-round btn-lg btn-icon"
                href="https://github.com/jamelait"
                rel="tooltip"
                title=""
                data-original-title="GitHub"
              >
                <i className="fa fa-github" />
              </a>
              {/* <a
                className="btn btn-default btn-round btn-lg btn-icon"
                href="https://medium.com/@jamelait"
                rel="tooltip"
                title=""
                data-original-title="Medium"
              >
                <i className="fa fa-medium" />
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Hero
