import React from 'react'
import ReactGA from 'react-ga'

// import { Link } from "gatsby"

import Layout from '../components/layout'
// import Image from '../components/image'
import SEO from '../components/seo'
import Hero from '../components/hero'

export default class IndexPage extends React.Component {
  componentDidMount = () => {
    ReactGA.initialize('UA-117105247-2')
    ReactGA.pageview(window.location.pathname + window.location.search)
  }

  render_en_pause() {
    return (
      <div
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          width: '30em',
          height: '18em',
          marginTop: '-9em',
          marginLeft: '-15em',
        }}
      >
        <SEO title="En pause" />
        <h1>En pause</h1>
      </div>
    )
  }

  render() {
    return (
      <Layout>
        <SEO
          title="Jamel AÏT OUARRAOU | Freelance .NET & Web developer"
          keywords={[`js`, `.net`, `france`, `toulouse`, `freelance`, `developer`, `developpeur`]}
        />
        <Hero />
      </Layout>
    )
  }
}
